import { GraphQLClient } from "graphql-request";
import * as Dom from "graphql-request/dist/types.dom";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BlockScalar: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  JsonScalar: any;
};

export type Alternate = {
  __typename?: "Alternate";
  fullSlug: Scalars["String"];
  id: Scalars["Int"];
  isFolder?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  parentId?: Maybe<Scalars["Int"]>;
  published: Scalars["Boolean"];
  slug: Scalars["String"];
};

export type Asset = {
  __typename?: "Asset";
  alt?: Maybe<Scalars["String"]>;
  copyright?: Maybe<Scalars["String"]>;
  filename: Scalars["String"];
  focus?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type BlogComponent = {
  __typename?: "BlogComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["BlockScalar"]>;
  categories?: Maybe<Array<Maybe<Scalars["String"]>>>;
  component?: Maybe<Scalars["String"]>;
  isFeatured?: Maybe<Scalars["Boolean"]>;
  products?: Maybe<Scalars["JsonScalar"]>;
  seo?: Maybe<Scalars["JsonScalar"]>;
  summary?: Maybe<Scalars["String"]>;
  thumbnail?: Maybe<Asset>;
  title?: Maybe<Scalars["String"]>;
};
export type PlantComponent = {
  __typename?: "PlantComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["BlockScalar"]>;
  component?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  thumbnail?: Maybe<Asset>;
  title?: Maybe<Scalars["String"]>;
};
export type BlogFilterQuery = {
  categories?: InputMaybe<FilterQueryOperations>;
  isFeatured?: InputMaybe<FilterQueryOperations>;
  summary?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
  letter?: InputMaybe<FilterQueryOperations>;
};
export type PlantFilterQuery = {
  letter?: InputMaybe<FilterQueryOperations>;
};
export type Test = {
  in?: Maybe<Scalars["String"]>;
};
export type RecipepageFilterQuery = {
  isFeatured?: InputMaybe<FilterQueryOperations>;
  recipe_summary?: InputMaybe<FilterQueryOperations>;
  recipe_title?: InputMaybe<FilterQueryOperations>;
};

export type BlogItem = {
  __typename?: "BlogItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<BlogComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type BlogItems = {
  __typename?: "BlogItems";
  items?: Maybe<Array<Maybe<BlogItem>>>;
  total?: Maybe<Scalars["Int"]>;
};
export type RecipepageItems = {
  __typename?: "RecipepageItems";
  items?: Maybe<Array<Maybe<RecipepageItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type BlogoverviewComponent = {
  __typename?: "BlogoverviewComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  featuredArticle?: Maybe<Story>;
  seo?: Maybe<Scalars["JsonScalar"]>;
  title?: Maybe<Scalars["String"]>;
};

export type BlogoverviewComponentFeaturedArticleArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  language?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type BlogoverviewFilterQuery = {
  description?: InputMaybe<FilterQueryOperations>;
  featuredArticle?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type BlogoverviewItem = {
  __typename?: "BlogoverviewItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<BlogoverviewComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type BlogoverviewItems = {
  __typename?: "BlogoverviewItems";
  items?: Maybe<Array<Maybe<BlogoverviewItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type CartComponent = {
  __typename?: "CartComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  cta?: Maybe<Story>;
  empty_text?: Maybe<Scalars["String"]>;
  empty_title?: Maybe<Scalars["String"]>;
  payment_note?: Maybe<Scalars["String"]>;
  payments_description?: Maybe<Scalars["String"]>;
  payments_title?: Maybe<Scalars["String"]>;
  seo?: Maybe<Scalars["JsonScalar"]>;
  slider_description?: Maybe<Scalars["String"]>;
  slider_title?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  tiered_discount_enabler_cart?: Maybe<Scalars["Boolean"]>;
  gifts_enabled?: Maybe<Scalars["Boolean"]>;
  usps?: Maybe<Scalars["BlockScalar"]>;
};
export type AccountComponent = {
  __typename?: "CartComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  cards?: Maybe<Scalars["BlockScalar"]>;
};

export type CartComponentCtaArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  language?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type CartFilterQuery = {
  cta?: InputMaybe<FilterQueryOperations>;
  empty_text?: InputMaybe<FilterQueryOperations>;
  empty_title?: InputMaybe<FilterQueryOperations>;
  payment_note?: InputMaybe<FilterQueryOperations>;
  payments_description?: InputMaybe<FilterQueryOperations>;
  payments_title?: InputMaybe<FilterQueryOperations>;
  slider_description?: InputMaybe<FilterQueryOperations>;
  slider_title?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type CartItem = {
  __typename?: "CartItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CartComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};
export type AccountItem = {
  __typename?: "AccountItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<AccountComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};
export type CartItems = {
  __typename?: "CartItems";
  items?: Maybe<Array<Maybe<CartItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type CategoryComponent = {
  __typename?: "CategoryComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type CategoryFilterQuery = {
  title?: InputMaybe<FilterQueryOperations>;
};

export type CategoryItem = {
  __typename?: "CategoryItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CategoryComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type CategoryItems = {
  __typename?: "CategoryItems";
  items?: Maybe<Array<Maybe<CategoryItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type ContentItem = {
  __typename?: "ContentItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<Scalars["JsonScalar"]>;
  content_string?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type ContentItems = {
  __typename?: "ContentItems";
  items?: Maybe<Array<Maybe<ContentItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type Datasource = {
  __typename?: "Datasource";
  id: Scalars["Int"];
  name: Scalars["String"];
  slug: Scalars["String"];
};

export type DatasourceEntries = {
  __typename?: "DatasourceEntries";
  items: Array<DatasourceEntry>;
  total: Scalars["Int"];
};

export type DatasourceEntry = {
  __typename?: "DatasourceEntry";
  dimensionValue?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  name: Scalars["String"];
  value: Scalars["String"];
};

export type Datasources = {
  __typename?: "Datasources";
  items: Array<Datasource>;
};

export type DynamicpageComponent = {
  __typename?: "DynamicpageComponent";
  Header?: Maybe<Asset>;
  Theme?: Maybe<String>;
  background_color?: Maybe<String>;
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["BlockScalar"]>;
  component?: Maybe<Scalars["String"]>;
  seo?: Maybe<Scalars["JsonScalar"]>;
};

export type DynamicpageItem = {
  __typename?: "DynamicpageItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<DynamicpageComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type DynamicpageItems = {
  __typename?: "DynamicpageItems";
  items?: Maybe<Array<Maybe<DynamicpageItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type FilterQueryOperations = {
  /** Must match all values of given array */
  all_in_array?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Greater than date (Exmples: 2019-03-03 or 2020-03-03T03:03:03) */
  gt_date?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Greater than float value */
  gt_float?: InputMaybe<Scalars["Float"]>;
  /** Greater than integer value */
  gt_int?: InputMaybe<Scalars["Int"]>;
  /** Matches exactly one value */
  in?: InputMaybe<Scalars["String"]>;
  /** Matches any value of given array */
  in_array?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Matches exactly one integer value */
  in_int?: InputMaybe<Scalars["Int"]>;
  /** Matches exactly one value with a wildcard search using * */
  like?: InputMaybe<Scalars["String"]>;
  /** Less than date (Format: 2019-03-03 or 2020-03-03T03:03:03) */
  lt_date?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Less than float value */
  lt_float?: InputMaybe<Scalars["Float"]>;
  /** Less than integer value */
  lt_int?: InputMaybe<Scalars["Int"]>;
  /** Matches all without the given value */
  not_in?: InputMaybe<Scalars["String"]>;
  /** Matches all without the given value */
  not_like?: InputMaybe<Scalars["String"]>;
};

export type FiltersComponent = {
  __typename?: "FiltersComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  tag_groups?: Maybe<Scalars["BlockScalar"]>;
};

export type FiltersItem = {
  __typename?: "FiltersItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FiltersComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type FiltersItems = {
  __typename?: "FiltersItems";
  items?: Maybe<Array<Maybe<FiltersItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type FooterComponent = {
  __typename?: "FooterComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  general_links?: Maybe<Scalars["BlockScalar"]>;
  link_list?: Maybe<Scalars["BlockScalar"]>;
  newsletter_button?: Maybe<Scalars["String"]>;
  newsletter_description?: Maybe<Scalars["String"]>;
  newsletter_message?: Maybe<Scalars["String"]>;
  newsletter_placeholder?: Maybe<Scalars["String"]>;
  newsletter_title?: Maybe<Scalars["String"]>;
  newsletter_color?: Maybe<Scalars["BlockScalar"]>;
  payment_options?: Maybe<Array<Maybe<Scalars["String"]>>>;
  review_icon?: Maybe<Asset>;
  review_text?: Maybe<Scalars["String"]>;
  social_links?: Maybe<Scalars["BlockScalar"]>;
  social_title?: Maybe<Scalars["String"]>;
};

export type FooterFilterQuery = {
  description?: InputMaybe<FilterQueryOperations>;
  newsletter_button?: InputMaybe<FilterQueryOperations>;
  newsletter_description?: InputMaybe<FilterQueryOperations>;
  newsletter_message?: InputMaybe<FilterQueryOperations>;
  newsletter_placeholder?: InputMaybe<FilterQueryOperations>;
  newsletter_title?: InputMaybe<FilterQueryOperations>;
  payment_options?: InputMaybe<FilterQueryOperations>;
  review_text?: InputMaybe<FilterQueryOperations>;
  social_title?: InputMaybe<FilterQueryOperations>;
};

export type FooterItem = {
  __typename?: "FooterItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FooterComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type FooterItems = {
  __typename?: "FooterItems";
  items?: Maybe<Array<Maybe<FooterItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type HomeComponent = {
  __typename?: "HomeComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["BlockScalar"]>;
  component?: Maybe<Scalars["String"]>;
  seo?: Maybe<Scalars["JsonScalar"]>;
};

export type HomeItem = {
  __typename?: "HomeItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<HomeComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type HomeItems = {
  __typename?: "HomeItems";
  items?: Maybe<Array<Maybe<HomeItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type LinkEntries = {
  __typename?: "LinkEntries";
  items: Array<LinkEntry>;
};

export type LinkEntry = {
  __typename?: "LinkEntry";
  id?: Maybe<Scalars["Int"]>;
  isFolder?: Maybe<Scalars["Boolean"]>;
  isStartpage?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["Int"]>;
  position?: Maybe<Scalars["Int"]>;
  published?: Maybe<Scalars["Boolean"]>;
  slug?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["String"]>;
};

export type NavigationComponent = {
  __typename?: "NavigationComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  cta?: Maybe<Scalars["JsonScalar"]>;
  cta_color?: any;
  cta_text_color?: any;
  link_list?: Maybe<Scalars["BlockScalar"]>;
  usp?: Maybe<Scalars["BlockScalar"]>;
  popup_seconds_delay?: Maybe<Scalars["Int"]>;
  popup_days_delay?: Maybe<Scalars["Int"]>;
  review_date?: Maybe<Scalars["String"]>;
  review_text?: Maybe<Scalars["String"]>;
  review_name?: Maybe<Scalars["String"]>;
  popup?: Maybe<Scalars["BlockScalar"]>;
  isGreenFridayPopupEnabled?: boolean;
  is_show_popup_on_click?: boolean;
};

export type NavigationItem = {
  __typename?: "NavigationItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<NavigationComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type NavigationItems = {
  __typename?: "NavigationItems";
  items?: Maybe<Array<Maybe<NavigationItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type NewsletterComponent = {
  __typename?: "NewsletterComponent";
  Body?: Maybe<Scalars["BlockScalar"]>;
  Footer?: Maybe<Scalars["BlockScalar"]>;
  Header?: Maybe<Scalars["BlockScalar"]>;
  template_name?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
};

export type NewsletterItem = {
  __typename?: "NewsletterItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<NewsletterComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type NewsletterItems = {
  __typename?: "NewsletterItems";
  items?: Maybe<Array<Maybe<NewsletterItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type ProductComponent = {
  __typename?: "ProductComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  featured_products?: Maybe<Scalars["JsonScalar"]>;
  featured_products_title?: Maybe<Scalars["String"]>;
};

export type ProductFilterQuery = {
  featured_products_title?: InputMaybe<FilterQueryOperations>;
};

export type ProductItem = {
  __typename?: "ProductItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type ProductItems = {
  __typename?: "ProductItems";
  items?: Maybe<Array<Maybe<ProductItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type ProductdetailComponent = {
  __typename?: "ProductdetailComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  blog_description?: Maybe<Scalars["String"]>;
  blog_title?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  cta?: Maybe<Story>;
  cta2?: Maybe<Story>;
  links?: Maybe<Scalars["BlockScalar"]>;
  popular_description?: Maybe<Scalars["String"]>;
  popular_title?: Maybe<Scalars["String"]>;
  recommendations_description?: Maybe<Scalars["String"]>;
  recommendations_title?: Maybe<Scalars["String"]>;
  usps?: Maybe<Scalars["BlockScalar"]>;
  tiered_discount_enabler?: Maybe<Scalars["Boolean"]>;
  type_recommendation_title?: Maybe<Scalars["String"]>;
  type_recommendation_description?: Maybe<Scalars["String"]>;
};

export type ProductdetailComponentCtaArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  language?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type ProductdetailComponentCta2Args = {
  fields?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  language?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type ProductdetailFilterQuery = {
  blog_description?: InputMaybe<FilterQueryOperations>;
  blog_title?: InputMaybe<FilterQueryOperations>;
  cta?: InputMaybe<FilterQueryOperations>;
  cta2?: InputMaybe<FilterQueryOperations>;
  popular_description?: InputMaybe<FilterQueryOperations>;
  popular_title?: InputMaybe<FilterQueryOperations>;
  recommendations_description?: InputMaybe<FilterQueryOperations>;
  recommendations_title?: InputMaybe<FilterQueryOperations>;
  type_recommendation_title?: InputMaybe<FilterQueryOperations>;
  type_recommendation_description?: InputMaybe<FilterQueryOperations>;
  tiered_discount_enabler?: any;
};

export type ProductdetailItem = {
  __typename?: "ProductdetailItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductdetailComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type ProductdetailItems = {
  __typename?: "ProductdetailItems";
  items?: Maybe<Array<Maybe<ProductdetailItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type ProductlistingComponent = {
  __typename?: "ProductlistingComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  image?: Maybe<Asset>;
  seo?: Maybe<Scalars["JsonScalar"]>;
  title?: Maybe<Scalars["String"]>;
};

export type ProductlistingFilterQuery = {
  title?: InputMaybe<FilterQueryOperations>;
};

export type ProductlistingItem = {
  __typename?: "ProductlistingItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductlistingComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type ProductlistingItems = {
  __typename?: "ProductlistingItems";
  items?: Maybe<Array<Maybe<ProductlistingItem>>>;
  total?: Maybe<Scalars["Int"]>;
};
export type QueryType = {
  __typename?: "QueryType";
  BlogItem?: Maybe<BlogItem>;
  RecipepageItem?: Maybe<RecipepageItem>;
  BlogItems?: Maybe<BlogItems>;
  RecipepageItems?: Maybe<RecipepageItems>;
  BlogoverviewItem?: Maybe<BlogoverviewItem>;
  BlogoverviewItems?: Maybe<BlogoverviewItems>;
  CartItem?: Maybe<CartItem>;
  AccountItem?: Maybe<AccountItem>;
  CartItems?: Maybe<CartItems>;
  CategoryItem?: Maybe<CategoryItem>;
  CategoryItems?: Maybe<CategoryItems>;
  ContentNode?: Maybe<ContentItem>;
  ContentNodes?: Maybe<ContentItems>;
  DatasourceEntries?: Maybe<DatasourceEntries>;
  Datasources?: Maybe<Datasources>;
  DynamicpageItem?: Maybe<DynamicpageItem>;
  DynamicpageItems?: Maybe<DynamicpageItems>;
  FiltersItem?: Maybe<FiltersItem>;
  FiltersItems?: Maybe<FiltersItems>;
  FooterItem?: Maybe<FooterItem>;
  FooterItems?: Maybe<FooterItems>;
  HomeItem?: Maybe<HomeItem>;
  HomeItems?: Maybe<HomeItems>;
  Links?: Maybe<LinkEntries>;
  NavigationItem?: Maybe<NavigationItem>;
  NavigationItems?: Maybe<NavigationItems>;
  NewsletterItem?: Maybe<NewsletterItem>;
  NewsletterItems?: Maybe<NewsletterItems>;
  ProductItem?: Maybe<ProductItem>;
  ProductItems?: Maybe<ProductItems>;
  ProductdetailItem?: Maybe<ProductdetailItem>;
  ProductdetailItems?: Maybe<ProductdetailItems>;
  ProductlistingItem?: Maybe<ProductlistingItem>;
  ProductlistingItems?: Maybe<ProductlistingItems>;
  RateLimit?: Maybe<RateLimit>;
  SearchItem?: Maybe<SearchItem>;
  SearchItems?: Maybe<SearchItems>;
  Space?: Maybe<Space>;
  Tags?: Maybe<Tags>;
  TemplateItem?: Maybe<TemplateItem>;
  TemplateItems?: Maybe<TemplateItems>;
  TermsandconditionsItem?: Maybe<TermsandconditionsItem>;
  TermsandconditionsItems?: Maybe<TermsandconditionsItems>;
  UspItem?: Maybe<UspItem>;
  UspItems?: Maybe<UspItems>;
};

export type QueryTypeBlogItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeBlogItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  filter_query_v2?: InputMaybe<BlogFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeBlogoverviewItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeBlogoverviewItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  filter_query_v2?: InputMaybe<BlogoverviewFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeCartItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeCartItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  filter_query_v2?: InputMaybe<CartFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeCategoryItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeCategoryItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  filter_query_v2?: InputMaybe<CategoryFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeContentNodeArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeContentNodesArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeDatasourceEntriesArgs = {
  datasource?: InputMaybe<Scalars["String"]>;
  dimension?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
};

export type QueryTypeDatasourcesArgs = {
  by_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeDynamicpageItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeDynamicpageItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeFiltersItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeFiltersItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeFooterItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeFooterItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  filter_query_v2?: InputMaybe<FooterFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeHomeItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeHomeItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeLinksArgs = {
  paginated?: InputMaybe<Scalars["Boolean"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeNavigationItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeNavigationItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeNewsletterItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeNewsletterItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeProductItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeProductItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  filter_query_v2?: InputMaybe<ProductFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeProductdetailItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeProductdetailItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  filter_query_v2?: InputMaybe<ProductdetailFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeProductlistingItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeProductlistingItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  filter_query_v2?: InputMaybe<ProductlistingFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeSearchItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeSearchItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  filter_query_v2?: InputMaybe<SearchFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeTagsArgs = {
  starts_with?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeTemplateItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeTemplateItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeTermsandconditionsItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeTermsandconditionsItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeUspItemArgs = {
  find_by?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["Int"]>;
  id: Scalars["ID"];
  language?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
};

export type QueryTypeUspItemsArgs = {
  by_slugs?: InputMaybe<Scalars["String"]>;
  by_uuids?: InputMaybe<Scalars["String"]>;
  by_uuids_ordered?: InputMaybe<Scalars["String"]>;
  excluding_fields?: InputMaybe<Scalars["String"]>;
  excluding_ids?: InputMaybe<Scalars["String"]>;
  excluding_slugs?: InputMaybe<Scalars["String"]>;
  fallback_lang?: InputMaybe<Scalars["String"]>;
  filter_query?: InputMaybe<Scalars["JsonScalar"]>;
  filter_query_v2?: InputMaybe<UspFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars["String"]>;
  first_published_at_lt?: InputMaybe<Scalars["String"]>;
  from_release?: InputMaybe<Scalars["String"]>;
  is_startpage?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<Scalars["String"]>;
  page?: InputMaybe<Scalars["Int"]>;
  per_page?: InputMaybe<Scalars["Int"]>;
  published_at_gt?: InputMaybe<Scalars["String"]>;
  published_at_lt?: InputMaybe<Scalars["String"]>;
  resolve_links?: InputMaybe<Scalars["String"]>;
  resolve_relations?: InputMaybe<Scalars["String"]>;
  search_term?: InputMaybe<Scalars["String"]>;
  sort_by?: InputMaybe<Scalars["String"]>;
  starts_with?: InputMaybe<Scalars["String"]>;
  with_tag?: InputMaybe<Scalars["String"]>;
};

export type RateLimit = {
  __typename?: "RateLimit";
  maxCost: Scalars["Int"];
};

export type SearchComponent = {
  __typename?: "SearchComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["BlockScalar"]>;
  component?: Maybe<Scalars["String"]>;
  seo?: Maybe<Scalars["JsonScalar"]>;
  title?: Maybe<Scalars["String"]>;
};

export type SearchFilterQuery = {
  title?: InputMaybe<FilterQueryOperations>;
};

export type SearchItem = {
  __typename?: "SearchItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<SearchComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type SearchItems = {
  __typename?: "SearchItems";
  items?: Maybe<Array<Maybe<SearchItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type Space = {
  __typename?: "Space";
  domain: Scalars["String"];
  id: Scalars["Int"];
  languageCodes: Array<Maybe<Scalars["String"]>>;
  name: Scalars["String"];
  version: Scalars["Int"];
};

export type Story = {
  __typename?: "Story";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<Scalars["JsonScalar"]>;
  createdAt?: Maybe<Scalars["String"]>;
  firstPublishedAt?: Maybe<Scalars["String"]>;
  fullSlug?: Maybe<Scalars["String"]>;
  groupId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  isStartpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  metaData?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  publishedAt?: Maybe<Scalars["String"]>;
  releaseId?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sortByDate?: Maybe<Scalars["String"]>;
  tagList?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translatedSlugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type Tag = {
  __typename?: "Tag";
  name: Scalars["String"];
  taggingsCount: Scalars["Int"];
};

export type Tags = {
  __typename?: "Tags";
  items: Array<Tag>;
};

export type TemplateComponent = {
  __typename?: "TemplateComponent";
  Body?: Maybe<Scalars["BlockScalar"]>;
  Footer?: Maybe<Scalars["BlockScalar"]>;
  Header?: Maybe<Scalars["BlockScalar"]>;
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  sendinblue_configs?: Maybe<Scalars["BlockScalar"]>;
};

export type TemplateItem = {
  __typename?: "TemplateItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<TemplateComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type TemplateItems = {
  __typename?: "TemplateItems";
  items?: Maybe<Array<Maybe<TemplateItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type TermsandconditionsComponent = {
  __typename?: "TermsandconditionsComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  terms_and_conditions?: Maybe<Scalars["JsonScalar"]>;
};

export type TermsandconditionsItem = {
  __typename?: "TermsandconditionsItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<TermsandconditionsComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type TermsandconditionsItems = {
  __typename?: "TermsandconditionsItems";
  items?: Maybe<Array<Maybe<TermsandconditionsItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type TranslatedSlug = {
  __typename?: "TranslatedSlug";
  lang: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  path?: Maybe<Scalars["String"]>;
};

export type UspComponent = {
  __typename?: "UspComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  usps?: Maybe<Scalars["BlockScalar"]>;
};

export type UspFilterQuery = {
  title?: InputMaybe<FilterQueryOperations>;
};

export type UspItem = {
  __typename?: "UspItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<UspComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};

export type UspItems = {
  __typename?: "UspItems";
  items?: Maybe<Array<Maybe<UspItem>>>;
  total?: Maybe<Scalars["Int"]>;
};

export type BlogItemQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;
export type PlantItemQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;
export type RecipesItemQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;
export type CollectionsItemQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;
export type IngredientsItemQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;
export type PlantoverviewItemQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;
export type RecipepageItemQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type BlogItemQuery = {
  __typename?: "QueryType";
  BlogItem?: {
    __typename?: "BlogItem";
    id?: number | null;
    name?: string | null;
    slug?: string | null;
    uuid?: string | null;
    content?: {
      __typename?: "BlogComponent";
      title?: string | null;
      summary?: string | null;
      categories?: Array<string | null> | null;
      body?: any | null;
      seo?: any | null;
      _editable?: string | null;
      _uid?: string | null;
      thumbnail?: {
        __typename?: "Asset";
        filename: string;
        focus?: string | null;
        alt?: string | null;
      } | null;
    } | null;
  } | null;
};
export type PlantItemQuery = {
  __typename?: "QueryType";
  PlantItem?: {
    __typename?: "PlantItem";
    id?: number | null;
    name?: string | null;
    slug?: string | null;
    uuid?: string | null;
    content?: {
      __typename?: "PlantComponent";
      title?: string | null;
      summary?: string | null;
      body?: any | null;
      botanische_naam?: string | null;
      botanische_synoniemen?: string | null;
      familie?: string | null;
      plantonderdeel?: string | null;
      inci?: string | null;
      _editable?: string | null;
      _uid?: string | null;
      thumbnail?: {
        __typename?: "Asset";
        filename: string;
        focus?: string | null;
        alt?: string | null;
      } | null;
    } | null;
  } | null;
};
export type RecipesItemQuery = {
  __typename?: "QueryType";
  RecipesItem?: {
    __typename?: "RecipesItem";
    id?: number | null;
    name?: string | null;
    slug?: string | null;
    uuid?: string | null;
    content?: {
      __typename?: "RecipesComponent";
      title?: string | null;
      description?: string | null;
    } | null;
  } | null;
};
export type CollectionsItemQuery = {
  __typename?: "QueryType";
  CollectionsItem?: {
    __typename?: "CollectionsItem";
    id?: number | null;
    name?: string | null;
    slug?: string | null;
    uuid?: string | null;
    content?: {
      __typename?: "CollectionsComponent";
      title?: string | null;
      description?: string | null;
      collections?: any;
    } | null;
  } | null;
};
export type IngredientsItemQuery = {
  __typename?: "QueryType";
  IngredientsItem?: {
    __typename?: "IngredientsItem";
    id?: number | null;
    name?: string | null;
    slug?: string | null;
    uuid?: string | null;
    content?: {
      __typename?: "IngredientsComponent";
      title?: string | null;
      description?: string | null;
    } | null;
  } | null;
};
export type PlantoverviewItemQuery = {
  __typename?: "QueryType";
  PlantoverviewItem?: {
    __typename?: "PlantoverviewItem";
    id?: number | null;
    name?: string | null;
    slug?: string | null;
    uuid?: string | null;
    content?: {
      __typename?: "PlantoverviewItemComponent";
      title?: string | null;
      description?: string | null;
    } | null;
  } | null;
};
export type RecipepageItemQuery = {
  __typename?: "QueryType";
  RecipepageItem?: {
    __typename?: "RecipepageItem";
    id?: number | null;
    name?: string | null;
    slug?: string | null;
    uuid?: string | null;
    content?: {
      __typename?: "RecipepageComponent";
      recipe_id?: number | null;
      recipe_title?: string | null;
      recipe_description?: string | null;
      recipe_summary?: string | null;
      recipe_button_enabled?: boolean | null;
      recipe_button_color?: string | null;
      recipe_video_url?: any | null;
      recipe_prep_time?: number | null;
      recipe_total_time?: number | null;
      recipe_yield?: number | null;
      recipe_price_uom: string | null;
      recipe_price_portion?: number | null;
      recipe_price_per?: string | null;
      recipe_ingredients: any | null;
      recipe_tools: any | null;
      recipe_difficulty: any | null;
      recipe_author?: string | null;
      recipe_keywords?: string | null;
      recipe_date_published?: string | null;
      recipe_ingredients_instructions?: any | null;
      seo?: any | null;
      _editable?: string | null;
      _uid?: string | null;
      recipe_thumbnail?: {
        __typename?: "Asset";
        filename: string;
        focus?: string | null;
        alt?: string | null;
      } | null;
    } | null;
  } | null;
};
export type RecipepageItem = {
  __typename?: "RecipepageItem";
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<RecipepageComponent>;
  created_at?: Maybe<Scalars["String"]>;
  default_full_slug?: Maybe<Scalars["String"]>;
  first_published_at?: Maybe<Scalars["String"]>;
  full_slug?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_startpage?: Maybe<Scalars["Boolean"]>;
  lang?: Maybe<Scalars["String"]>;
  meta_data?: Maybe<Scalars["JsonScalar"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["Int"]>;
  path?: Maybe<Scalars["String"]>;
  position?: Maybe<Scalars["Int"]>;
  published_at?: Maybe<Scalars["String"]>;
  release_id?: Maybe<Scalars["Int"]>;
  slug?: Maybe<Scalars["String"]>;
  sort_by_date?: Maybe<Scalars["String"]>;
  tag_list?: Maybe<Array<Maybe<Scalars["String"]>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars["String"]>;
};
export type RecipepageComponent = {
  __typename?: "RecipepageComponent";
  _editable?: Maybe<Scalars["String"]>;
  _uid?: Maybe<Scalars["String"]>;
  component?: Maybe<Scalars["String"]>;
  isFeatured?: Maybe<Scalars["Boolean"]>;
  products?: Maybe<Scalars["JsonScalar"]>;
  seo?: Maybe<Scalars["JsonScalar"]>;
  recipe_thumbnail?: Maybe<Asset>;
  recipe_title?: Maybe<Scalars["String"]>;
  recipe_description?: Maybe<Scalars["String"]>;
  recipe_summary?: Maybe<Scalars["String"]>;
  recipe_button_enabled?: Maybe<Scalars["Boolean"]>;
  recipe_button_color?: Maybe<Scalars["String"]>;
  recipe_video_url?: Maybe<Scalars["BlockScalar"]>;
  recipe_prep_time?: Maybe<Number>;
  recipe_total_time?: Maybe<Number>;
  recipe_yield?: Maybe<String>;
  recipe_price_uom?: Maybe<String>;
  recipe_price_portion?: Maybe<Number>;
  recipe_price_per?: Maybe<String>;
  recipe_ingredients?: Maybe<Scalars["BlockScalar"]>;
  recipe_tools?: Maybe<Scalars["BlockScalar"]>;
  recipe_difficulty?: Maybe<Scalars["String"]>;
  recipe_author?: Maybe<Scalars["String"]>;
  recipe_keywords?: Maybe<Scalars["String"]>;
  recipe_date_published?: Maybe<Scalars["String"]>;
  recipe_ingredients_instructions?: Maybe<Scalars["BlockScalar"]>;
};

export type BlogItemsQueryVariables = Exact<{
  perPage?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  exclude?: InputMaybe<Scalars["String"]>;
  query?: InputMaybe<BlogFilterQuery>;
  searchTerm?: InputMaybe<Scalars["String"]>;
  withTag?: InputMaybe<Scalars["String"]>;
}>;
export type PlantItemsQueryVariables = Exact<{
  perPage?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  exclude?: InputMaybe<Scalars["String"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
  withTag?: InputMaybe<Scalars["String"]>;
  query?: InputMaybe<PlantFilterQuery>;
}>;
export type RecipepageItemsQueryVariables = Exact<{
  perPage?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
  exclude?: InputMaybe<Scalars["String"]>;
  query?: InputMaybe<RecipepageFilterQuery>;
}>;

export type BlogItemsQuery = {
  __typename?: "QueryType";
  BlogItems?: {
    __typename?: "BlogItems";
    total?: number | null;
    items?: Array<{
      __typename?: "BlogItem";
      id?: number | null;
      name?: string | null;
      slug?: string | null;
      full_slug?: string | null;
      uuid?: string | null;
      published_at?: string | null;
      sort_by_date?: string | null;
      content?: {
        __typename?: "BlogComponent";
        categories?: Array<string | null> | null;
        products?: any | null;
        isFeatured?: boolean | null;
        title?: string | null;
        summary?: string | null;
        _editable?: string | null;
        _uid?: string | null;
        thumbnail?: {
          __typename?: "Asset";
          filename: string;
          focus?: string | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};
export type PlantItemsQuery = {
  __typename?: "QueryType";
  PlantItems?: {
    __typename?: "PlantItems";
    total?: number | null;
    items?: Array<{
      __typename?: "PlantItem";
      id?: number | null;
      name?: string | null;
      slug?: string | null;
      full_slug?: string | null;
      uuid?: string | null;
      published_at?: string | null;
      sort_by_date?: string | null;
      content?: {
        __typename?: "PlantComponent";
        title?: string | null;
        summary?: string | null;
        letter?: string | null;
        _editable?: string | null;
        _uid?: string | null;
        thumbnail?: {
          __typename?: "Asset";
          filename: string;
          focus?: string | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};
export type RecipepageItemsQuery = {
  __typename?: "QueryType";
  RecipepageItems?: {
    __typename?: "RecipepageItems";
    total?: number | null;
    items?: Array<{
      __typename?: "RecipepageItem";
      id?: number | null;
      name?: string | null;
      slug?: string | null;
      full_slug?: string | null;
      uuid?: string | null;
      published_at?: string | null;
      sort_by_date?: string | null;
      content?: {
        __typename?: "RecipepageComponent";
        recipe_title?: string | null;
        recipe_description?: string | null;
        recipe_summary?: string | null;
        recipe_button_enabled?: boolean | null;
        recipe_button_color?: string | null;
        recipe_video_url?: any | null;
        recipe_prep_time?: number | null;
        recipe_total_time?: number | null;
        recipe_yield?: number | null;
        recipe_price_uom: string | null;
        recipe_price_portion?: number | null;
        recipe_ingredients: any | null;
        recipe_price_per?: string | null;
        recipe_tools: any | null;
        recipe_difficulty: string | null;
        recipe_author?: string | null;
        recipe_keywords?: string | null;
        recipe_date_published?: string | null;
        recipe_ingredients_instructions?: any | null;
        seo?: any | null;
        _editable?: string | null;
        _uid?: string | null;
        recipe_thumbnail?: {
          __typename?: "Asset";
          filename: string;
          focus?: string | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};
export type BlogOverviewQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type BlogOverviewQuery = {
  __typename?: "QueryType";
  BlogoverviewItem?: {
    __typename?: "BlogoverviewItem";
    content?: {
      __typename?: "BlogoverviewComponent";
      seo?: any | null;
      title?: string | null;
      description?: string | null;
      featuredArticle?: {
        __typename?: "Story";
        id?: number | null;
        publishedAt?: string | null;
        slug?: string | null;
        content?: any | null;
        firstPublishedAt?: string | null;
        sortByDate?: string | null;
      } | null;
    } | null;
  } | null;
};
export type UserProperysQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;
export type CallToActionQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;
export type WishlistPopupQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;
export type CallToActionQuery = {
  __typename?: "QueryType";
  ContentNode?: {
    __typename?: "ContentItem";
    content?: any | null;
    uuid?: string | null;
  } | null;
};
export type UserPropertysQuery = {
  __typename?: "QueryType";
  UserpropertysItem?: {
    __typename?: "ContentItem";
    content?: {
      ages?: any;
      skinConditions?: any;
      bodyParts?: any;
      skinTypes?: any;
    };
    uuid?: string | null;
  } | null;
};
export type WishlistQuery = {
  __typename?: "QueryType";
  ContentNode?: {
    __typename?: "ContentItem";
    content?: any | null;
    uuid?: string | null;
  } | null;
};
export type CartQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type AccountQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type CartQuery = {
  __typename?: "QueryType";
  CartItem?: {
    __typename?: "CartItem";
    content?: {
      __typename?: "CartComponent";
      title?: string | null;
      empty_title?: string | null;
      empty_text?: string | null;
      usps?: any | null;
      payments_title?: string | null;
      payments_description?: string | null;
      payment_note?: string | null;
      slider_title?: string | null;
      tiered_discount_enabler_cart?: boolean | null;
      slider_description?: string | null;
      minimumOrder?: number | null;
      gifts_enabled?: boolean | null;
      seo?: any | null;
      _editable?: string | null;
      cta?: {
        __typename?: "Story";
        content?: any | null;
      } | null;
    } | null;
  } | null;
};
export type AccountQuery = {
  __typename?: "QueryType";
  AccountItem?: {
    __typename?: "AccountItem";
    content?: {
      __typename?: "AccountComponent";
      cards?: string | null;
    } | null;
  } | null;
};
export type DynamicPageByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DynamicPageByIdQuery = {
  __typename?: "QueryType";
  DynamicpageItem?: {
    __typename?: "DynamicpageItem";
    uuid?: string | null;
    content?: {
      __typename?: "DynamicpageComponent";
      body?: any | null;
      seo?: any | null;
      _editable?: string | null;
      Noindex: boolean | null;
    } | null;
  } | null;
};

export type DynamicPagesQueryVariables = Exact<{
  filter?: InputMaybe<Scalars["JsonScalar"]>;
  prefix?: InputMaybe<Scalars["String"]>;
  perPage?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
}>;

export type DynamicPagesQuery = {
  __typename?: "QueryType";
  DynamicpageItems?: {
    __typename?: "DynamicpageItems";
    total?: number | null;
    items?: Array<{
      __typename?: "DynamicpageItem";
      slug?: string | null;
      full_slug?: string | null;
      published_at?: string | null;
      uuid?: string | null;
      translated_slugs?: Array<{
        __typename?: "TranslatedSlug";
        lang: string;
        path?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type FiltersItemsQueryVariables = Exact<{ [key: string]: never }>;

export type FiltersItemsQuery = {
  __typename?: "QueryType";
  FiltersItems?: {
    __typename?: "FiltersItems";
    items?: Array<{
      __typename?: "FiltersItem";
      uuid?: string | null;
      content?: {
        __typename?: "FiltersComponent";
        tag_groups?: any | null;
        _editable?: string | null;
        _uid?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type FooterQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type FooterQuery = {
  __typename?: "QueryType";
  FooterItem?: {
    __typename?: "FooterItem";
    published_at?: string | null;
    uuid?: string | null;
    content?: {
      __typename?: "FooterComponent";
      description?: string | null;
      review_text?: string | null;
      newsletter_title?: string | null;
      newsletter_description?: string | null;
      newsletter_color?: any;
      newsletter_placeholder?: string | null;
      newsletter_button?: string | null;
      newsletter_message?: string | null;
      link_list?: any | null;
      social_title?: string | null;
      social_links?: any | null;
      payment_options?: Array<string | null> | null;
      general_links?: any | null;
      _editable?: string | null;
      review_icon?: {
        __typename?: "Asset";
        filename: string;
        alt?: string | null;
        id?: number | null;
      } | null;
    } | null;
  } | null;
};

export type HomeQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type HomeQuery = {
  __typename?: "QueryType";
  HomeItem?: {
    __typename?: "HomeItem";
    uuid?: string | null;
    content?: {
      __typename?: "HomeComponent";
      body?: any | null;
      seo?: any | null;
      _editable?: string | null;
    } | null;
  } | null;
};

export type NavigationQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type NavigationQuery = {
  __typename?: "QueryType";
  NavigationItem?: {
    __typename?: "NavigationItem";
    published_at?: string | null;
    uuid?: string | null;
    content?: {
      __typename?: "NavigationComponent";
      link_list?: any | null;
      usp?: any | null;
      cta?: any | null;
      cta_color?: any | null;
      cta_text_color?: any | null;
      _editable?: string | null;
      popup_seconds_delay?: number | null;
      popup_days_delay?: number | null;
      popup?: any | null;
      review_date?: string | null;
      review_text?: string | null;
      review_name?: string | null;
      isGreenFridayPopupEnabled?: boolean | null;
      is_show_popup_on_click?: boolean | null;
    } | null;
  } | null;
};

export type PdpQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;
export type ProductDetailsItemVariables = Exact<{
  slug: Scalars["ID"];
}>;
export type PdpQuery = {
  __typename?: "QueryType";
  ProductdetailItem?: {
    __typename?: "ProductdetailItem";
    uuid?: string | null;
    content?: {
      __typename?: "ProductdetailComponent";
      usps?: any | null;
      links?: any | null;
      blog_title?: string | null;
      blog_description?: string | null;
      type_recommendation_description?: string | null;
      tiered_discount_enabler?: boolean | null;
      type_recommendation_title?: string | null;
      recommendations_title?: string | null;
      recommendations_description?: string | null;
      popular_title?: string | null;
      popular_description?: string | null;
      _editable?: string | null;
      cta?: { __typename?: "Story"; content?: any | null } | null;
      cta2?: { __typename?: "Story"; content?: any | null } | null;
    } | null;
  } | null;
};

export type ProductByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProductByIdQuery = {
  __typename?: "QueryType";
  ProductItem?: {
    __typename?: "ProductItem";
    uuid?: string | null;
    slug?: string | null;
    content?: {
      __typename?: "ProductComponent";
      _editable?: string | null;
      featured_products?: any | null;
      featured_products_title?: string | null;
    } | null;
  } | null;
};

export type ProductsQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type ProductsQuery = {
  __typename?: "QueryType";
  ProductlistingItem?: {
    __typename?: "ProductlistingItem";
    id?: number | null;
    name?: string | null;
    slug?: string | null;
    uuid?: string | null;
    content?: {
      __typename?: "ProductlistingComponent";
      title?: string | null;
      description?: string | null;
      seo?: any | null;
      _editable?: string | null;
      _uid?: string | null;
      image?: {
        __typename?: "Asset";
        alt?: string | null;
        filename: string;
      } | null;
    } | null;
  } | null;
};

export type SearchQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type SearchQuery = {
  __typename?: "QueryType";
  SearchItem?: {
    __typename?: "SearchItem";
    uuid?: string | null;
    content?: {
      __typename?: "SearchComponent";
      title?: string | null;
      body?: any | null;
      seo?: any | null;
      _editable?: string | null;
    } | null;
  } | null;
};

export type SearchBlogItemsQueryVariables = Exact<{
  perPage?: InputMaybe<Scalars["Int"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
}>;
export type SearchRecipepageItemsQueryVariables = Exact<{
  perPage?: InputMaybe<Scalars["Int"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
}>;

export type SearchBlogItemsQuery = {
  __typename?: "QueryType";
  BlogItems?: {
    __typename?: "BlogItems";
    items?: Array<{
      __typename?: "BlogItem";
      id?: number | null;
      name?: string | null;
      tag_list?: any;
      slug?: string | null;
      full_slug?: string | null;
      uuid?: string | null;
      published_at?: any;
      content?: {
        __typename?: "BlogComponent";
        title?: string | null;
        summary?: string | null;
        categories?: Array<string | null> | null;
        _editable?: string | null;
        _uid?: string | null;
        thumbnail?: {
          __typename?: "Asset";
          filename: string;
          focus?: string | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};
export type TagsQuery = {
  __typename?: "QueryType";
  Tags?: {
    __typename?: "Tags";
    items?: Array<{
      name: string | null;
    } | null> | null;
  } | null;
};
export type ProductDetailsItemQuery = {
  __typename?: "QueryType";
  ProductdetailsItem?: {
    __typename?: "ProductdetailsItem";
    content?: {
      isIconsEnabled: boolean | null;
      icons: any | null;
      before_description: any | null;
      after_description: any | null;
      components: any | null;
      bgColor: string | null;
      noIndex: boolean | null;
      isUpsellDisabled: boolean | null;
      isDescriptionDisabled: boolean | null;
      isTabsDisabled: boolean | null;
      IsIngredientsDisabled?: boolean | null;
      isPopularProductsDisabled: boolean | null;
      isCTADisabled: boolean | null;
      stroke_color?: any | null;
      footer_color?: any | null;
      accordions?: any | null;
      product_choice?: any | null;
      isProductRecomendationsDisabled?: boolean | null;
      isReviewsDisabled?: boolean | null;
      video_embed?: any | null;
      video_embed_preview?: any | null;
      embed_thumbnail?: any | null;
      crowdfund_text?: string | null;
      crowdfund_text_enabler?: boolean | null;
    } | null;
  } | null;
};
export type SearchRecipepageItemsQuery = {
  __typename?: "QueryType";
  RecipepageItems?: {
    __typename?: "RecipepageItems";
    total?: number | null;
    items?: Array<{
      __typename?: "RecipepageItem";
      id?: number | null;
      name?: string | null;
      slug?: string | null;
      full_slug?: string | null;
      uuid?: string | null;
      published_at?: string | null;
      sort_by_date?: string | null;
      content?: {
        __typename?: "RecipepageComponent";
        recipe_title?: string | null;
        recipe_description?: string | null;
        recipe_summary?: string | null;
        recipe_button_enabled?: boolean | null;
        recipe_button_color?: string | null;
        recipe_video_url?: any | null;
        recipe_prep_time?: number | null;
        recipe_total_time?: number | null;
        recipe_yield?: number | null;
        recipe_price_uom: string | null;
        recipe_price_portion?: number | null;
        recipe_ingredients: any | null;
        recipe_price_per?: string | null;
        recipe_tools: any | null;
        recipe_difficulty: string | null;
        recipe_author?: string | null;
        recipe_keywords?: string | null;
        recipe_date_published?: string | null;
        recipe_ingredients_instructions?: any | null;
        seo?: any | null;
        _editable?: string | null;
        _uid?: string | null;
        recipe_thumbnail?: {
          __typename?: "Asset";
          filename: string;
          focus?: string | null;
          alt?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};
export type TemplateItemQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type TemplateItemQuery = {
  __typename?: "QueryType";
  TemplateItem?: {
    __typename?: "TemplateItem";
    id?: number | null;
    name?: string | null;
    uuid?: string | null;
    slug?: string | null;
    content?: {
      __typename?: "TemplateComponent";
      Body?: any | null;
      template_name?: string | null;
      subject?: string | null;
      theme_color?: string | null;
      _editable?: string | null;
      _uid?: string | null;
      component?: string | null;
    } | null;
  } | null;
};

export type TermsAndConditionsQueryVariables = Exact<{ [key: string]: never }>;

export type TermsAndConditionsQuery = {
  __typename?: "QueryType";
  TermsandconditionsItems?: {
    __typename?: "TermsandconditionsItems";
    items?: Array<{
      __typename?: "TermsandconditionsItem";
      content?: {
        __typename?: "TermsandconditionsComponent";
        _uid?: string | null;
        _editable?: string | null;
        terms_and_conditions?: any | null;
      } | null;
    } | null> | null;
  } | null;
};

export const BlogItemDocument = gql`
  query blogItem($slug: ID!) {
    BlogItem(
      id: $slug
      resolve_links: "url"
      resolve_relations: "blog_bar.blog_list,call_to_action.call_to_action,usp_bar.usp"
    ) {
      id
      name
      slug
      uuid
      content {
        title
        summary
        categories
        thumbnail {
          filename
          focus
          alt
        }
        body
        seo
        _editable
        _uid
      }
    }
  }
`;
export const PlantItemDocument = gql`
  query plantItem($slug: ID!) {
    PlantItem(
      id: $slug
      resolve_links: "url"
      resolve_relations: "blog_card.blog_list,call_to_action.call_to_action,usp_bar.usp,blog_card.blog"
    ) {
      id
      name
      slug
      uuid
      content {
        title
        summary
        botanische_naam
        botanische_synoniemen
        familie
        plantonderdeel
        inci
        thumbnail {
          filename
          focus
          alt
        }
        body
        _editable
        _uid
      }
    }
  }
`;
export const RecipesItemDocument = gql`
  query recipeItem($slug: ID!) {
    RecipesItem(id: $slug) {
      id
      name
      slug
      uuid
      content {
        title
        description
        _editable
        _uid
      }
    }
  }
`;
export const CollectionsItemDocument = gql`
  query collectionsItem($slug: ID!) {
    CollectionsItem(id: $slug) {
      id
      name
      slug
      uuid
      content {
        title
        description
        collections
        _editable
        _uid
      }
    }
  }
`;
export const IngredientsItemDocument = gql`
  query ingredientsItem($slug: ID!) {
    IngredientsItem(id: $slug) {
      id
      name
      slug
      uuid
      content {
        title
        description
        _editable
        _uid
      }
    }
  }
`;
export const PlantoverviewItemDocument = gql`
  query PlantoverviewItem($slug: ID!) {
    PlantoverviewItem(id: $slug) {
      id
      name
      slug
      uuid
      content {
        title
        description
        _editable
        _uid
      }
    }
  }
`;
export const RecipepageItemDocument = gql`
  query recipepageItem($slug: ID!) {
    RecipepageItem(
      id: $slug
      resolve_links: "url"
      resolve_relations: "blog_bar.blog_list,call_to_action.call_to_action,usp_bar.usp"
    ) {
      id
      name
      slug
      uuid
      content {
        recipe_id
        recipe_title
        recipe_description
        recipe_summary
        recipe_button_enabled
        recipe_button_color
        recipe_video_url {
          id
          url
          linktype
        }
        recipe_thumbnail {
          filename
          focus
          alt
        }
        recipe_prep_time
        recipe_total_time
        recipe_yield
        recipe_price_uom
        recipe_price_portion
        recipe_ingredients
        recipe_price_per
        recipe_tools
        recipe_difficulty
        recipe_author
        recipe_keywords
        recipe_date_published
        recipe_ingredients_instructions
        seo
        _editable
        _uid
      }
    }
  }
`;
export const BlogItemsDocument = gql`
  query blogItems(
    $perPage: Int
    $page: Int
    $exclude: String
    $query: BlogFilterQuery
    $searchTerm: String
    $withTag: String
  ) {
    BlogItems(
      per_page: $perPage
      page: $page
      excluding_ids: $exclude
      filter_query_v2: $query
      sort_by: "sort_by_date:desc"
      search_term: $searchTerm
      with_tag: $withTag
    ) {
      items {
        id
        name
        slug
        full_slug
        uuid
        published_at
        sort_by_date
        content {
          categories
          products
          isFeatured
          title
          summary
          thumbnail {
            filename
            focus
            alt
          }
          _editable
          _uid
        }
      }
      total
    }
  }
`;
export const PlantItemsDocument = gql`
  query plantItems(
    $perPage: Int
    $page: Int
    $exclude: String
    $searchTerm: String
    $withTag: String
    $query: PlantFilterQuery
  ) {
    PlantItems(
      per_page: $perPage
      page: $page
      excluding_ids: $exclude
      sort_by: "sort_by_date:desc"
      search_term: $searchTerm
      with_tag: $withTag
      filter_query_v2: $query
    ) {
      items {
        id
        name
        slug
        full_slug
        uuid
        published_at
        sort_by_date
        content {
          title
          summary
          letter
          thumbnail {
            filename
            focus
            alt
          }
          _editable
          _uid
        }
      }
      total
    }
  }
`;
export const RecipepageItemsDocument = gql`
  query recipepageItems($perPage: Int, $page: Int, $exclude: String) {
    RecipepageItems(
      per_page: $perPage
      page: $page
      excluding_ids: $exclude
      sort_by: "sort_by_date:desc"
    ) {
      items {
        id
        name
        slug
        full_slug
        uuid
        published_at
        sort_by_date
        content {
          recipe_title
          recipe_description
          recipe_summary
          recipe_button_enabled
          recipe_button_color
          recipe_video_url {
            id
            url
            linktype
          }
          recipe_thumbnail {
            filename
            focus
            alt
          }
          recipe_prep_time
          recipe_total_time
          recipe_yield
          recipe_price_uom
          recipe_price_portion
          recipe_ingredients
          recipe_tools
          recipe_difficulty
          recipe_author
          recipe_price_per
          recipe_keywords
          recipe_date_published
          recipe_ingredients_instructions
          seo
          _editable
          _uid
        }
      }
      total
    }
  }
`;
export const SearchRecipepageItemsDocument = gql`
  query searchRecipepageItems($perPage: Int, $searchTerm: String) {
    RecipepageItems(
      per_page: $perPage
      search_term: $searchTerm
      sort_by: "sort_by_date:desc"
    ) {
      items {
        id
        name
        slug
        full_slug
        uuid
        published_at
        sort_by_date
        content {
          recipe_title
          recipe_description
          recipe_summary
          recipe_button_enabled
          recipe_button_color
          recipe_video_url {
            id
            url
            linktype
          }
          recipe_thumbnail {
            filename
            focus
            alt
          }
          recipe_prep_time
          recipe_total_time
          recipe_yield
          recipe_price_uom
          recipe_price_portion
          recipe_ingredients
          recipe_tools
          recipe_difficulty
          recipe_author
          recipe_price_per
          recipe_keywords
          recipe_date_published
          recipe_ingredients_instructions
          seo
          _editable
          _uid
        }
      }
      total
    }
  }
`;
export const BlogOverviewDocument = gql`
  query blogOverview($slug: ID!) {
    BlogoverviewItem(id: $slug) {
      content {
        seo
        title
        description
        featuredArticle {
          id
          publishedAt
          slug
          content
          firstPublishedAt
          sortByDate
        }
      }
    }
  }
`;
export const TagsDocument = gql`
  query tags {
    Tags {
      items {
        name
      }
    }
  }
`;
export const CallToActionDocument = gql`
  query callToAction($slug: ID!) {
    ContentNode(id: $slug, resolve_links: "url") {
      content
      uuid
    }
  }
`;
export const UserPropertysDocument = gql`
  query userpropertysItem($slug: ID!) {
    UserpropertysItem(id: $slug, resolve_links: "url") {
      content {
        ages
        skinTypes
        skinConditions
        bodyParts
      }
      uuid
    }
  }
`;
export const WishlistPopupDocument = gql`
  query callToAction($slug: ID!) {
    ContentNode(id: $slug) {
      content
      uuid
    }
  }
`;
export const CartDocument = gql`
  query cart($slug: ID!) {
    CartItem(id: $slug) {
      content {
        title
        empty_title
        empty_text
        usps
        cta {
          content
        }
        payments_title
        payments_description
        payment_note
        slider_title
        slider_description
        tiered_discount_enabler_cart
        minimumOrder
        gifts_enabled
        seo
        _editable
      }
    }
  }
`;
export const AccountDocument = gql`
  query account($slug: ID!) {
    AccountItem(id: $slug) {
      content {
        cards
      }
    }
  }
`;
export const DynamicPageByIdDocument = gql`
  query dynamicPageById($id: ID!) {
    DynamicpageItem(
      id: $id
      resolve_links: "url"
      resolve_relations: "blog_bar.blog_list,call_to_action.call_to_action,usp_bar.usp,recipe_bar.Recipe,blog_card.blog"
    ) {
      content {
        background_color
        body
        seo
        bg_color
        bg_enabled
        gradient_enabled
        Noindex
        _editable
      }
      uuid
    }
  }
`;
export const DynamicPagesDocument = gql`
  query dynamicPages(
    $filter: JsonScalar
    $prefix: String = ""
    $perPage: Int = 100
    $page: Int
  ) {
    DynamicpageItems(
      filter_query: $filter
      per_page: $perPage
      page: $page
      starts_with: $prefix
    ) {
      items {
        slug
        full_slug
        published_at
        uuid
        translated_slugs {
          lang
          path
        }
      }
      total
    }
  }
`;
export const FiltersItemsDocument = gql`
  query filtersItems {
    FiltersItems {
      items {
        uuid
        content {
          tag_groups
          _editable
          _uid
        }
      }
    }
  }
`;
export const FooterDocument = gql`
  query footer($slug: ID!) {
    FooterItem(id: $slug, resolve_links: "url") {
      published_at
      content {
        description
        review_icon {
          filename
          alt
          id
        }
        review_text
        newsletter_title
        newsletter_description
        newsletter_color
        newsletter_placeholder
        newsletter_button
        newsletter_message
        link_list
        social_title
        social_links
        payment_options
        general_links
        _editable
      }
      uuid
    }
  }
`;
export const HomeDocument = gql`
  query home($slug: ID!) {
    HomeItem(
      id: $slug
      resolve_links: "url"
      resolve_relations: "blog_bar.blog_list,call_to_action.call_to_action,usp_bar.usp"
    ) {
      content {
        body
        backgroundColor
        seo
        _editable
      }
      uuid
    }
  }
`;
export const NavigationDocument = gql`
  query navigation($slug: ID!) {
    NavigationItem(id: $slug) {
      published_at
      content {
        link_list
        usp
        cta
        cta_text_color
        cta_color
        popup_seconds_delay
        popup_days_delay
        popup
        review_date
        review_text
        review_name
        isGreenFridayPopupEnabled
        is_show_popup_on_click
        _editable
      }
      uuid
    }
  }
`;
export const PdpDocument = gql`
  query pdp($slug: ID!) {
    ProductdetailItem(id: $slug, resolve_links: "url") {
      content {
        usps
        links
        blog_title
        blog_description
        recommendations_title
        recommendations_description
        type_recommendation_title
        type_recommendation_description
        tiered_discount_enabler

        popular_title
        popular_description
        cta {
          content
        }
        cta2 {
          content
        }
        _editable
      }
      uuid
    }
  }
`;
export const ProductDetailsItemDocument = gql`
  query ProductDetailsItem($slug: ID!) {
    ProductdetailsItem(
      id: $slug
      resolve_links: "url"
      resolve_relations: "blog_card.blog,usp_bar.usp"
    ) {
      content {
        icons
        embed_thumbnail {
          filename
        }
        isIconsEnabled
        before_description
        after_description
        components
        noIndex
        isUpsellDisabled
        isDescriptionDisabled
        isTabsDisabled
        isCTADisabled
        isPopularProductsDisabled
        bgColor
        stroke_color
        footer_color
        IsIngredientsDisabled
        product_choice
        accordions
        isProductRecomendationsDisabled
        isReviewsDisabled
        video_embed
        video_embed_preview
        crowdfund_text
        crowdfund_text_enabler
      }
      uuid
    }
  }
`;
export const ProductByIdDocument = gql`
  query productById($id: ID!) {
    ProductItem(id: $id) {
      content {
        _editable
        featured_products
        featured_products_title
      }
      uuid
      slug
    }
  }
`;
export const ProductsDocument = gql`
  query products($slug: ID!) {
    ProductlistingItem(id: $slug) {
      id
      name
      slug
      uuid
      content {
        title
        description
        seo
        image {
          alt
          filename
        }
        _editable
        _uid
      }
    }
  }
`;
export const SearchDocument = gql`
  query search($slug: ID!) {
    SearchItem(
      id: $slug
      resolve_links: "url"
      resolve_relations: "blog_bar.blog_list,call_to_action.call_to_action,usp_bar.usp"
    ) {
      content {
        title
        body
        seo
        _editable
      }
      uuid
    }
  }
`;
export const SearchBlogItemsDocument = gql`
  query searchBlogItems($perPage: Int, $searchTerm: String) {
    BlogItems(per_page: $perPage, search_term: $searchTerm) {
      items {
        id
        name
        tag_list
        slug
        full_slug
        uuid
        published_at
        content {
          title
          summary
          categories
          thumbnail {
            filename
            focus
            alt
          }
          _editable
          _uid
        }
      }
      total
    }
  }
`;
export const TemplateItemDocument = gql`
  query TemplateItem($slug: ID!) {
    TemplateItem(
      id: $slug
      resolve_relations: "blogCardList.blogs,recipe_card.recipe,recipe/blogs_list.content,recipe_blogs_horizontal.content,recipe_blogs_vertical.content"
    ) {
      id
      name
      uuid
      slug
      content {
        Body
        template_name
        subject
        theme_color
        _editable
        _uid
        component
      }
    }
  }
`;
export const TermsAndConditionsDocument = gql`
  query termsAndConditions {
    TermsandconditionsItems {
      items {
        content {
          _uid
          _editable
          terms_and_conditions
        }
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    blogItem(
      variables: BlogItemQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<BlogItemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<BlogItemQuery>(BlogItemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "blogItem"
      );
    },
    plantItem(
      variables: PlantItemQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<PlantItemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PlantItemQuery>(PlantItemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "plantItem"
      );
    },
    recipesItem(
      variables: RecipesItemQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RecipesItemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RecipesItemQuery>(RecipesItemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "recipesItem"
      );
    },
    collectionsItem(
      variables: CollectionsItemQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CollectionsItemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CollectionsItemQuery>(
            CollectionsItemDocument,
            variables,
            {
              ...requestHeaders,
              ...wrappedRequestHeaders,
            }
          ),
        "collectionsItem"
      );
    },
    ingredientsItem(
      variables: IngredientsItemQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<IngredientsItemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IngredientsItemQuery>(
            IngredientsItemDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "ingredientsItem"
      );
    },
    plantoverviewItem(
      variables: PlantoverviewItemQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<PlantoverviewItemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PlantoverviewItemQuery>(
            PlantoverviewItemDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        " plantoverviewItem"
      );
    },
    recipepageItem(
      variables: RecipepageItemQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RecipepageItemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RecipepageItemQuery>(
            RecipepageItemDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "recipepageItem"
      );
    },
    blogItems(
      variables?: BlogItemsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<BlogItemsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<BlogItemsQuery>(BlogItemsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "blogItems"
      );
    },
    userProperys(
      variables: UserProperysQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<UserPropertysQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserPropertysQuery>(UserPropertysDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "userProperys"
      );
    },
    plantItems(
      variables?: PlantItemsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<PlantItemsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PlantItemsQuery>(PlantItemsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "plantItems"
      );
    },
    recipepageItems(
      variables?: RecipepageItemsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<RecipepageItemsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RecipepageItemsQuery>(
            RecipepageItemsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "recipepageItems"
      );
    },
    blogOverview(
      variables: BlogOverviewQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<BlogOverviewQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<BlogOverviewQuery>(BlogOverviewDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "blogOverview"
      );
    },
    tags(requestHeaders?: Dom.RequestInit["headers"]): Promise<TagsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TagsQuery>(TagsDocument, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "tags"
      );
    },
    callToAction(
      variables: CallToActionQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CallToActionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CallToActionQuery>(CallToActionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "callToAction"
      );
    },
    wishlistPopup(
      variables: WishlistPopupQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<WishlistQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WishlistQuery>(WishlistPopupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "wishlistPopup"
      );
    },
    cart(
      variables: CartQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<CartQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CartQuery>(CartDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "cart"
      );
    },
    account(
      variables: AccountQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<AccountQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AccountQuery>(AccountDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "account"
      );
    },
    dynamicPageById(
      variables: DynamicPageByIdQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DynamicPageByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DynamicPageByIdQuery>(
            DynamicPageByIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "dynamicPageById"
      );
    },
    dynamicPages(
      variables?: DynamicPagesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<DynamicPagesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DynamicPagesQuery>(DynamicPagesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "dynamicPages"
      );
    },
    filtersItems(
      variables?: FiltersItemsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<FiltersItemsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FiltersItemsQuery>(FiltersItemsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "filtersItems"
      );
    },
    footer(
      variables: FooterQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<FooterQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FooterQuery>(FooterDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "footer"
      );
    },
    home(
      variables: HomeQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<HomeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<HomeQuery>(HomeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "home"
      );
    },
    navigation(
      variables: NavigationQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<NavigationQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<NavigationQuery>(NavigationDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "navigation"
      );
    },
    pdp(
      variables: PdpQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<PdpQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PdpQuery>(PdpDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "pdp"
      );
    },
    productDetailsItem(
      variables: ProductDetailsItemVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ProductDetailsItemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProductDetailsItemQuery>(
            ProductDetailsItemDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "productDetailsItem"
      );
    },
    productById(
      variables: ProductByIdQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ProductByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProductByIdQuery>(ProductByIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "productById"
      );
    },
    products(
      variables: ProductsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<ProductsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProductsQuery>(ProductsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "products"
      );
    },
    search(
      variables: SearchQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SearchQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SearchQuery>(SearchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "search"
      );
    },
    searchBlogItems(
      variables?: SearchBlogItemsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SearchBlogItemsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SearchBlogItemsQuery>(
            SearchBlogItemsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "searchBlogItems"
      );
    },
    searchRecipepageItems(
      variables?: SearchRecipepageItemsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<SearchRecipepageItemsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SearchRecipepageItemsQuery>(
            SearchRecipepageItemsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "searchBlogItems"
      );
    },
    TemplateItem(
      variables: TemplateItemQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TemplateItemQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TemplateItemQuery>(TemplateItemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "TemplateItem"
      );
    },
    termsAndConditions(
      variables?: TermsAndConditionsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"]
    ): Promise<TermsAndConditionsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TermsAndConditionsQuery>(
            TermsAndConditionsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        "termsAndConditions"
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
